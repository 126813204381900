import activity from './m-solve-real-world-problems.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Solve Real World Problems',
  icon: 'mdi-lightbulb',
  description: 'Solve Real World Problems!',
  status: true,
  setupRequired: false,
  styling: {
    borderColor: '#fcd523'
  },
  fields: [
    {
      roles: ['employer'],
      type: 'noSetup',
      title: 'No setup necessary',
      noSetup: true,
      position: 0
    },
    {
      roles: ['participant'],
      name: 'm-solve-real-world-problems',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {
        boards: []
      },
      value: {}
    },
    {
      roles: ['participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 2,
      preset: {},
      setup: {},
      value: {}
    }
  ],
  presetFields: [
    {
      roles: ['employer'],
      type: 'noTweaks',
      title: 'No tweaking necessary',
      noSetup: true,
      position: 0
    }
  ]
};
